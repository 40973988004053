//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EventDashboard',
  created() {
    if (this.$q.platform.mobile) {
      this.$emit('switch', 'about');
    }
  },
  methods: {
    switchState(state) {
      this.$emit('switch', state);
    }
  },
  data() {
    return {
      tab: (this.$q.platform.mobile) ? 'about' : 'posts'
    }
  }
}
